.image {
  // img {
  //   overflow: hidden;
  //   will-change: transform;
  //   @include transition($target: transform);

  //   &:hover {
  //     transform: translateY(-2px);

  //   }

  //   &:active {
  //     transform: translateY(0);
  //   }
  // }
}

.image-zoomed {
  // border-radius: $radius-large;
  transition: none !important;
}