$background: $black;
$title: $white;
$text: $grey-light;

.Projects {
  padding: $size-1 0;
  color: $text;

  strong {
    color: $grey-dark;
  }

  hr {
    background: $grey-darker;
    margin: 0;
  }

  li {
    list-style: none;
  }

  

  .subtitle {
    color: $grey;
  }

  .title {
    color: $title;
  }

  .Thumbnail {
    
  }
}

.Project-title {
  font-weight: 800;
}

