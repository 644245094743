.Thumbnail {
  display: block;
  position: relative;
  overflow: hidden;
  will-change: scale;
  @include transition($speed: $speed-slow);
  cursor: zoom-in;
  
  img {
    display: block;
    height: auto;
    border-radius: $radius-large;
  }

  &:hover,
  &.is-open  {
    box-shadow: 0 3px 30px 0 rgba(0,0,0,0.2);
    transform: scale(1.02) translateZ(1px);
  }
}


