.content {
  p,
  ul {
    font-family: "Merriweather";
    line-height: 1.75;
  }

  ul {
    margin: 0;

    li {
      margin: 0;
      list-style-position: inside;
    }
  }
}