.Play {
  $play-width: $size-1*5;
  
  display: inline-block;
  
  color: inherit;
  background: $white;
  
  width: $play-width;
  height: $size-3*2;
  padding: $size-6/2 $size-4;
  padding-left: $size-2*2;
  line-height: $size-4;
  border-radius: $radius-large;
  box-shadow: 0px 0px 0px 2px rgba($black, 0.15), 0 3px 15px 0 rgba(0,0,0,0.1);
  
  pointer-events: auto;
  text-align: center;
  
  position: fixed;
  z-index: 1;
  
  @include transition($speed: $speed-slow);
  will-change: scale;
  

  // RESPONSIVE

  @include mobile {
    margin-left: -$play-width/2;
    bottom: $size-4;
    bottom: $size-3;
    left: 50%;
  }

  @include tablet {
    right: 3vw;
    top: $size-1;
  }


  // STATES
  
  &:hover {
    box-shadow: 0px 0px 0px 2px rgba($black, 1), 0 3px 30px 0 rgba(0,0,0,0.2);
    transform: scale(1.02) translateZ(1px);
  }

  &:active {
    transform: scale(1) translateZ(1px);
    box-shadow: 0px 0px 0px 2px rgba($black, 1), 0 3px 20px 0 rgba(0,0,0,0.2);
  }

  
  
  // CHILDREN

  span {
    display: block;
    @include transition($speed: $speed-slow);
    color: $grey;
    will-change: color;
  }

  &-icon {
    position: absolute;
    top: 50%;
    margin-top: 0-$size-2/2;
    width: $size-2;
    left: $size-6;
  }

  &-text {
    // display: none;
    span {
      &:first-child {
        font-weight: $weight-bold;
        color: $black;
      }
    }
  }
}