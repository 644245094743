@mixin box {
  background: $white;
}

@mixin shadow {
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.05);
}

@mixin transition($target: all, $speed: $speed-fast, $delay: 0s, $ease: $ease-in-out-quad) {
  transition: all $speed $delay $ease;
  transition-property: $target;
}