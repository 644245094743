.Modal {
  
}

.Modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
  background-color: rgba($black, 0.98);
  display: flex;
  align-items: center;
  backdrop-filter: blur(10px);
  justify-content: center;
  
}

.Modal-content {
  outline: none;

  figure {
    cursor: zoom-out;
    box-shadow: 0px 2px 40px 0px rgba($black, 0.5);
    border-radius: $radius-large;
    overflow: hidden;
  }

  img {
    max-height: 90vh;
    max-width: 96vw;
  }
}