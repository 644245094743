$body-size: 18px;
$radius-large: 12px;
$gap: 64px;
$widescreen: 1024px + (2 * $gap);
$fullhd: 1244px + (2 * $gap);

// COLORS
$purple: #A98FDB;
$purple-light: rgb(205, 185, 243);


// FONTS
$weight-black: 900 !default;


// ANIMATIONS
$ease-in-out-quad: cubic-bezier(.455, .03, .515, .955);
$ease-out-quad: cubic-bezier(.25, .46, .45, .94);
$ease-in-quad: cubic-bezier(.55, .085, .68, .53);

// SPEED
$speed-fast: 0.1s;
$speed: 0.2s;
$speed-slow: 0.3s;

// LINKS
$link: $purple;
$link-hover: $purple-light;