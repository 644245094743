.Project {
  padding: $size-2*2 0;

  &:first-child {
    padding-top: $size-1;
  }
}

.Project-header {
  margin-bottom: $size-3 !important;
}

.Project-skills {
  color: $grey;
  font-weight: $weight-medium;
  display: inline-block;
}