.Face {
  $face-width: $size-2*2;
  
  width: $face-width;
  height: $face-width;

  overflow: hidden;
  border-radius: $face-width;
  box-shadow: 0 3px 15px 0 rgba(0,0,0,0.1);

  position: fixed;
  top: $size-2;
  left: 3vw;

  z-index: 1;

  @include mobile {
    left: 6vw;
    width: 14vw;
    height: 14vw;
  }
}