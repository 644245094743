html,
body {
  background: $black;
}

html,
body {
  &.is-open {
    overflow: hidden;
  }
}

img {
  vertical-align: middle;
}

a {
  
}


// BLOCK


.block {
  @include block;
  padding: $size-1*2 0;
}

.block-subtitle {
  font-weight: 200;
}

.block-title {
  margin-bottom: $size-large*2 !important;
  font-family: "Merriweather";
  font-weight: 900;
}

.block-item {
  margin-top: $size-large*2;
  margin-bottom: $size-large*2;
}