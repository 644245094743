$background: $white;
$title: $black;
$text: $grey-darker;

.Resume {
  background: $background;
  padding-top: $size-1*4;

  @include mobile {
    padding-top: 26vw;
  }

  hr {
    background: $grey-lighter;
    height: 1px;
  }

  .subtitle {
    color: $grey;
  }

  .title {
    color: $title;
    
  }

  .content {
    @include clearfix;

    figure {
      margin: 0;
    }

    p {
      color: $text;
    }
  }
}

.Resume-item-title {
  // font-family: "Merriweather";
  font-weight: 800;
}

.Resume-question {
  &:not(:last-child) {
    margin-bottom: $size-1;
  }
}

.Resume-images {
  width: $size-1*3;
  float: right;
  margin-left: $size-4;
  margin-top: $size-7/2;
  margin-bottom: $size-7/2;

}